import React from 'react';
import { graphql } from 'gatsby';
// import { Query } from 'react-apollo';
// import gql from 'graphql-tag';

import { Menu } from 'components/Navigation';
import Layout from 'components/Layout';
import SEO from 'components/seo';

// import { Container, Row } from 'components/GridSystem';
// import { BusinessFunction } from 'components/Text';
// import { CapabilityCard } from 'components/Cards';

import {
  // Capabilities,
  // Expertise,
  IntroService
} from 'components/Sections';

class Page extends React.Component {
  render () {
    const { data, location } = this.props;
    const siteTitle = data.site.siteMetadata.title;

    const capabilities = [
      {
        title: `Experience Design`,
        content: `Customer expectations are changing dramatically. Now, it’s not only essential to understand what customers need but how to optimally deliver those needs.`,
        src: `https://images.unsplash.com/photo-1517245386807-bb43f82c33c4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=600&q=80`
      },
      {
        title: `Services Sector`,
        content: `Support a remote workforce with access to product specs, designs and other critical files on any device.`,
        src: `https://images.unsplash.com/photo-1517245386807-bb43f82c33c4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=600&q=80`
      },
      {
        title: `Product Design`,
        content: `Support a remote workforce with access to product specs, designs and other critical files on any device.`,
        src: `https://images.unsplash.com/photo-1517245386807-bb43f82c33c4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=600&q=80`
      }
    ];
    const businessFunctions = [
      {
        title: `Streamline processes with automations`,
        content: `Support a remote workforce with access to product specs, designs and other critical files on any device.`
      },
      {
        title: `2 x processes with automations`,
        content: `Support a remote workforce with access to product specs, designs and other critical files on any device.`
      },
      {
        title: `3 x processes with automations`,
        content: `Support a remote workforce with access to product specs, designs and other critical files on any device.`
      }
    ];

    return (
      <Layout location={location} title={siteTitle}>
        <SEO title="What We Do" />
        <Menu colorScheme={`dark`} />
        <article>
        test
          {/*
          <IntroService
            label={`Discover`}
            intro={`Augmentation de capital, émission obligataire, levée de dettes et financement structuré, introduction en bourse`}
          />
          <Container>
            <Row>
              { capabilities && (
                capabilities.length > 0 && capabilities.map((props) => {
                  return (
                    <CapabilityCard {...props} />
                  );
                })
              )}
            </Row>
          </Container>
          <Container>
            <Row>
              { businessFunctions && (
                businessFunctions.length > 0 && businessFunctions.map((props) => {
                  return (
                    <BusinessFunction {...props} />
                  );
                })
              )}
            </Row>
          </Container>
          <Expertise />
          <Query query={APOLLO_QUERY}>
            {({ data, loading, error }) => {
              // console.log(`data`, data);
              if (loading) return <p>Loading pupper...</p>;
              if (error) {
                // console.log(`error`, error);
                return <p>Error: ${error.message}</p>;
              }

              const {
                image: src,
                name
              } = data.character;
              return (
                <div>
                  <h1>{name}</h1>
                  <img src={src} alt={name} style={{ maxWidth: 300 }} />
                </div>
              );
            }}
          </Query>
          <Capabilities />
          */}
        </article>
      </Layout>
    )
  }
}

// This query is executed at run time by Apollo.
// const APOLLO_QUERY = gql`
//   {
//     character(id: 1) {
//       name
//       image
//     }
//   }
// `;

export default Page;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
